import { $http } from '@core/http-client';
import { BulkActionCompletedToast } from '@shared/components/bulk-action-completed-toast';
import { BulkActionProgressToast } from '@shared/components/bulk-action-progress-toast';
import { IUser } from '@shared/models/IUser';
import {
  PrivateEnvDisposalEventKey,
  PrivateEnvUserEventKey,
  PrivateSocietyEventKey,
  PrivateUserEventKey,
  PusherEventKey,
  PusherEventPayload,
  PusherNotificationKey,
  PusherNotificationPayload,
} from '@shared/types/common/events';

import { ToastItem } from './PusherContext';

export const getEventToast = (
  event: PusherEventKey,
  suppliedPayload: ValueOf<PusherEventPayload>,
  user: IUser,
  onClose: (toastKey: string) => void,
  onClickLink: (link: string) => void
): ToastItem | undefined => {
  // console.log(`Pusher event: ${event}`, suppliedPayload);
  const time = Date.now();

  switch (event) {
    case PrivateSocietyEventKey.AcquisitionPublishedToSociety: {
      // const payload = suppliedPayload as PusherEventPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       eventString="added new requirement"
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = 'society-acquisitions.view';
      //         // data.link_params = { advert_id: payload.id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PrivateUserEventKey.BulkActionCompleted: {
      const payload = suppliedPayload as PusherEventPayload[typeof event];
      const key = `${event}_${payload.job_id}`;

      return {
        key: key,
        replaceKey: `${PrivateUserEventKey.BulkActionProgress}_${payload.job_id}`,
        time: time,
        content: (
          <BulkActionCompletedToast key={key} {...payload} onClose={() => onClose(key)} onClickLink={onClickLink} />
        ),
      };
    }

    case PrivateUserEventKey.BulkActionProgress: {
      const payload = suppliedPayload as PusherEventPayload[typeof event];
      const key = `${event}_${payload.job_id}`;
      return {
        key: key,
        time: time,
        content: <BulkActionProgressToast key={key} {...payload} onClose={() => onClose(key)} />,
      };
    }

    case PrivateSocietyEventKey.LettingPublishedToSociety: {
      // const payload = suppliedPayload as PusherEventPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       eventString="added new letting"
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = 'society-lettings.view';
      //         // data.link_params = { advert_id: payload.id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PrivateEnvUserEventKey.SocietyMatchMessageAdded: {
      // const payload = suppliedPayload as PusherEventPayload[typeof event];
      // const key = `${event}_${payload.disposal_id}_${payload.requirement_id}_${payload.message.id}`;

      // // TODO: Review this
      // if (payload.message.user_id == user.id) {
      //   return undefined;
      // }

      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.message.body}
      //       eventString="You have a new message"
      //       user={payload.message.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');

      //         if (payload.disposal_organisation_id == user.organisation?.id) {
      //           // notification.link = DISPOSALS.VIEWS.INTEREST_SCHEDULE_VIEW;
      //           // notification.link_params = {
      //           //     match_id: payload.message.object_id,
      //           //     id: payload.disposal_id,
      //           //     match_tab: 'agent_messages'
      //           // };
      //         } else if (payload.requirement_organisation_id == user.organisation?.id) {
      //           // notification.link = REQUIREMENTS.VIEWS.SHORTLIST_VIEW;
      //           // notification.link_params = {
      //           //     match_id: payload.message.object_id,
      //           //     requirement_id: payload.requirement_id,
      //           //     match_tab: 'agent_messages',
      //           //     match_message_tab: payload.disposal_organisation_id // organisation of the thread we're talking about
      //           // };
      //         }
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PrivateEnvDisposalEventKey.MatchBulkIndexUpdated:
      return undefined;

    case PrivateEnvDisposalEventKey.MatchIndexUpdated:
      return undefined;

    case PrivateEnvDisposalEventKey.MatchShowUpdated:
      return undefined;
  }
};

export const getNotificationToast = (
  event: PusherNotificationKey,
  suppliedPayload: ValueOf<PusherNotificationPayload>,
  onClose: (toastKey: string) => void,
  onClickLink: (link: string) => void
): ToastItem | undefined => {
  // console.log(`Pusher notification: ${event}`, suppliedPayload);
  // const time = Date.now();

  switch (event) {
    case PusherNotificationKey.UserOrTeamMentionedOnInterestScheduleComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = DISPOSALS.VIEWS.INTEREST_SCHEDULE_VIEW;
      //         // data.link_params = { id: payload.disposal_id, match_id: payload.match_id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherNotificationKey.UserOrTeamMentionedOnRequirementComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = REQUIREMENTS.VIEWS.VIEW;
      //         // data.link_params = { requirementId: payload.acquisition_id, requirement_tab: 'internal_comments' };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherNotificationKey.UserOrTeamMentionedOnShortlistComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = REQUIREMENTS.VIEWS.SHORTLIST_VIEW;
      //         // data.link_params = { requirement_id: payload.requirement_id, match_id: payload.match_id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherNotificationKey.UserOrTeamMentionedOnSocietyAcquisitionComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = REQUIREMENTS.VIEWS.VIEW_SOCIETY_ACQUISITION;
      //         // data.link_params = { advert_id: payload.society_acquisition_id, acquisition_tab: 'internal_comments' };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }
  }
};

export const addOrUpdateToast = (toast: ToastItem, _prev: ToastItem[]) => {
  let newToasts: ToastItem[] = [];
  if (toast.replaceKey) {
    // Remove a related existing toast
    // console.log('addOrUpdateToast. replace toast w/ key:', toast.replaceKey);
    newToasts = _prev.filter((item) => item.key !== toast.replaceKey);
  } else {
    newToasts = [..._prev];
  }

  const index = newToasts.findIndex((item) => item.key === toast.key);

  if (index !== -1) {
    // Update existing toast
    // console.log('addOrUpdateToast. update existing toast:', toast.key, toast);
    newToasts[index] = toast;
  } else {
    // Add new toast
    // console.log('addOrUpdateToast. add new toast:', toast.key, toast);
    newToasts.push(toast);
  }

  return newToasts;
};

export const openToastLink = (link: string) => {
  window.open(link, '_blank');
};

export const echoAuthorizer = (channel: any) => {
  return {
    authorize: (socketId: any, callback: any) => {
      $http
        .post('/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name,
        })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  };
};
